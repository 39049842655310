import { render, staticRenderFns } from "./ProductOrderForm.vue?vue&type=template&id=4f227e64"
import script from "./ProductOrderForm.vue?vue&type=script&lang=js"
export * from "./ProductOrderForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputField: require('/vercel/path0/components/general/form-elements/InputField.vue').default,Checkboxes: require('/vercel/path0/components/general/form-elements/Checkboxes.vue').default,FormMessage: require('/vercel/path0/components/general/FormMessage.vue').default,TransitionExpand: require('/vercel/path0/components/general/TransitionExpand.vue').default,AppButton: require('/vercel/path0/components/general/AppButton.vue').default})
