
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      state: null,
      form: {
        products: this.data.products,
        firstname: '',
        lastname: '',
        email: '',
        organisation: '',
        phone: '',
        street: '',
        zip: '',
        location: '',
        comment: '',
        terms_accepted: false,
      },
    }
  },

  computed: {},

  methods: {
    async submit() {
      this.state = 'loading'

      this.mappedProductsAmount()

      try {
        await this.$axios.$post('/api/products/order', this.form)

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          this.resetForm()
        })

        this.state = 'success'
      } catch (e) {
        this.state = 'error'

        console.log(e)
      }
    },

    resetForm() {
      this.form = {
        products: this.data.products,
        firstname: '',
        lastname: '',
        email: '',
        organisation: '',
        phone: '',
        street: '',
        zip: '',
        location: '',
        comment: '',
        terms_accepted: false,
      }

      this.$refs.form.reset()
    },

    mappedProductsAmount() {
      // set product quantity to 1 when null or 0
      return this.form.products.map((p) => {
        if (!p.amount || p.amount === 0) {
          p.amount = 1

          return p
        }

        return p
      })
    },
  },
}
